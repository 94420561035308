<template>
  <v-container
    tag="section"
    fluid
  >
    <v-checkbox v-model="freigegeben" label="Freigegebene Turnerinnen anzeigen" />
    <v-checkbox v-model="gesperrt" label="Gesperrte Turnerinnen anzeigen" />
    <v-checkbox v-model="leer" label="Nicht eingegebene Turnerinnen anzeigen" />
    <v-tabs
      v-model="tab"
      grow
      show-arrows
    >
      <v-tab v-for="l in ligen" :key="l._id">{{ l.name }} ({{ l.anzahl }})</v-tab>
      <v-tab-item v-for="l in ligen" :key="l._id">
        <v-card
          v-for="t in l.teams"
          :key="t._id"
        >
          <v-card-title><h2>{{ t.team.name }}</h2></v-card-title>
          <v-card-text>
            <v-data-table
              :headers="[
                { text: 'Nachname', value: 'person.familyName' },
                { text: 'Vorname', value: 'person.givenName' },
                { text: 'Geburtsdatum', value: 'person.birthDate' },
                { text: 'DTL-Einsätze', value: 'dtlgeraete', align: 'center' },
                { text: 'Freigegeben', value: 'dtlauthorized', align: 'center' },
                { text: 'Freigabe', value: 'action', align: 'center' }
              ]"
              :items="t.athletes"
              :items-per-page="-1"
            >
              <template #item.person.birthDate="{item}">
                {{ item.person.birthDate | date }}
              </template>
              <template #item.dtlauthorized="{item}">
                <v-icon v-if="item.dtlauthorized" color="green">far fa-check</v-icon>
                <v-icon v-else color="red">far fa-xmark</v-icon>
              </template>
              <template #item.action="{item}">
                <v-btn
                  v-if="item.dtlauthorized"
                  color="red"
                  @click="set(l._id, t.team._id, item.person._id, false)"
                >
                  Freigabe zurücknehmen
                </v-btn>
                <v-btn
                  v-else-if="item.dtlgeraete !== null"
                  color="green"
                  @click="set(l._id, t.team._id, item.person._id, true)"
                >
                  freigeben
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  ... on StbW2023 {
    leagues {
      _id
      name
      order
      teams {
        team {
          _id
          name
        }
        athletes {
          person { _id familyName givenName birthDate }
          dtlgeraete
          dtlauthorized
        }
      }
    }
  }
`

export default {
  name: 'dtlathletes',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Event: {},
    tab: 0,
    freigegeben: false,
    gesperrt: true,
    leer: false
  }),

  computed: {
    ligen () {
      return this.Event?.leagues
        ?.map(l => ({
          ...l,
          teams: (l.teams?.sort((a, b) => a.team.name < b.team.name ? -1 : 1) || []).map(t => ({
            ...t,
            athletes: t.athletes.filter(a => {
              if (a.dtlgeraete === null && this.leer) return true
              if (a.dtlauthorized === true && this.freigegeben) return true
              if (a.dtlauthorized === false && this.gesperrt) return true
              return false
            })
          }))
        }))
        ?.map(l => ({
          ...l,
          anzahl: l.teams.reduce((acc, curr) => acc + curr.athletes.length, 0)
        }))
        ?.sort((a, b) => a.order < b.order ? -1 : 1) || []
    }
  },

  methods: {
    set (liga, team, person, authorized) {
      this.mutate({
        mutation: gql`
            mutation($id: UUID!, $team: UUID!, $person: UUID!, $dtlauthorized: Boolean!) {
                StbW2023LeagueTeamAthleteDtlauthorizedUpdate(
                  id: $id, team: $team, person: $person, dtlauthorized: $dtlauthorized
                ) { _id }
            }
          `,
        variables: {
          id: liga,
          team,
          person,
          dtlauthorized: authorized
        }
      })
    }
  },

  apollo: {
    Event: {
      query: gql`
        query($id: UUID!) { Event(id: $id) { ${query} }}
      `,
      variables () {
        return {
          id: this.id
        }
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($id: UUID!) { EventUpdate(id: $id) { ${query} }}
        `,
        variables () {
          return {
            id: this.id
          }
        }
      }
    }
  }
}

</script>

<style scoped>

</style>
